<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 期间限定 -->
      <a style="display: block;" target="_blank" href="https://maidocoin.com/entrance/?lang=zh-cn">
        <el-image :src="qjxdImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>

      <!-- 积分 -->
      <a id="stepImgId" style="display: block;" target="_blank" href="https://tripellet.com/tcapp-download-b/sc">
        <el-image :src="stepImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>
      <!-- 设施一览表 -->
      <div style="background-color: #f3d2c3;">
        <el-image :src="modelcourse">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
        </el-image>
        <el-image :src="modelcourse2">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
        <el-image :src="placeholder_title">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </div>
      <!-- 使用方法 -->
      <!-- <div id="usage_box" class="usage_box">
        <div class="title">畅游关西必备的景点套票，精选大阪、神户、京都多个人气设施与美食店家，一週内可以任选三个或六个喜欢的设施，出示电子票券QR code就可以轻松入场体验。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同设施只可以使用一次，相同设施不可再入场<br>
          ●票劵使用效期为购买日起<span>90天内</span><br>
          EX：<span>7/1</span>购买票券⇒<span>票期:7/1~9/28</span><br>
          ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
          EX：<span>7/1</span>开始使用，<span>有效期限至7/7</span><br>
          ●有效期间内可任<span>选3项</span>或<span>6项</span>设施<br>
          ●注意: 单次购买複数票劵时，当其中一张票劵开始使用，所有票劵
          也将同步开通。(<span>使用效期7天</span>)<br>
          ●票券开通后，7天的使用效期无法超过票券<span>90天</span>的有效期间。<br>
           EX：票券期限<span>7/1~9/28</span>，若<span>9/27</span>开始使用，其使用效期为<span>9/27~9/28</span><br>
          ●无使用完毕不能兑现<br>
          ●请事先于以下连结确认各设施注意事项・营业时间・公休日情报
        </div>
      </div> -->
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <div class="xiao_btn_img" v-if="item.iconImgBr">
          <a v-if="item.iconImgBr1" target="_blank" :href="item.iconVideoHref"><img :src="xiao_btn_img1" alt=""></a>
          <a v-if="item.iconImgBr2" href="#stepImgId"><img :src="xiao_btn_img2" alt=""></a>
          <a v-if="item.iconImgBr3" href="javascript:;"><img :src="xiao_btn_img3" alt=""></a>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
                <div v-if="msg.title!=='注意事项'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兑换地点' && !contentItem.noColor">{{cItem}}<br></span>
                    <a class="dltAbq" :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方网站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfkansai/zh/havefun_kansai_sc.png'),
      qjxdImg: require('@/assets/images/hfkansai/zh/qjxd_banner_sc.png'),
      stepImg: require('@/assets/images/hfkansai/zh/4step_sc.png'),
      modelcourse: require('@/assets/images/hfkansai/zh/havefun_modelcourse_1_sc.jpg'),
      modelcourse2: require('@/assets/images/hfkansai/zh/havefun_modelcourse_2_sc.jpg'),
      placeholder_title: require('@/assets/images/hfkansai/zh/placeholder_title_SC.png'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      xiao_btn_img1: require('@/assets/images/hfkansai/zh/play_SC.png'),
      xiao_btn_img2: require('@/assets/images/hfkansai/jifen.png'),
      xiao_btn_img3: require('@/assets/images/hfkansai/zh/qjxd2_SC.png'),
      dataList: [
        // {
        //   id: 1,
        //   img: require('@/assets/images/hfkansai/zh/グループ 264.png'),
        //   title: ['阿倍野HARUKAS 300 展望台'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '兑换地点',
        //       content: [{ cid: 1, text: ['大阪市阿倍野区阿倍野筋1-1-43'] }, { cid: 2, text: ['(HARUKAS300展望台16楼)'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '官方网站',
        //       content: [{ cid: 1, text: ['https://www.abenoharukas-300.jp/zh/observatory/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事项',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['仅限使用一次，退场后无法再次入场，请知晓。'] },
        //         { cid: 2, text: ['使用当天前往16层服务台扫码入场。'] }
        //       ]
        //     }]
        // },
        {
          id: 2,
          img: require('@/assets/images/hfkansai/kintetsu_tc2.jpg'),
          title: ['近铁百货经典纪念套组兌換券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['大阪府大阪市阿倍野区阿倍野筋1-1-43'] }, { cid: 2, text: ['翼馆3.5楼 国际贵宾沙龙 Foreign Customer’s Salon'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://abenoharukas.d-kintetsu.co.jp/special/foreign/index-cs.html'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以兑换近铁百货经典纪念套组。','（今治高级毛巾&近铁百货店吉祥物资料夹1入套组）'] },
                { cid: 2, text: ['套组商品恕不退换，敬请知悉见谅。'] },
                { cid: 3, text: ['本活动仅限近铁百货海阔天空总店适用。'] },
                { cid: 4, text: ['图片仅供参考，实品请以实物为主。'] }
              ]
            }]
        },
        {
          id: 3,
          img: require('@/assets/images/hfkansai/zh/グループ 263.png'),
          title: ['大阪观光周游巴士Wonder Loop'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['大阪市中央区道顿崛1丁目16 沿着河边B1', '(Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://wonderloop.jp/zh-hant/wonder-loop/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请系安全带，并且请勿站立、移动变换座位。'] },
                { cid: 2, text: ['有活动、交通限制或引导等时，可能会停运或变更路线的情形发生。'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfkansai/zh/グループ 262.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV18T411w7z6/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['大阪道顿堀观光游艇 Wonder Cruise'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['大阪市中央区道顿崛1丁目16 沿着河边B1', '(Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://wondercruise.jp/doton/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['运行日程请在官方网站上确认。'] },
                { cid: 2, text: ['有时会因强风、降雨等气象条件而停止运行。'] },
                { cid: 3, text: ['满座的话可能会无法乘船。'] },
                { cid: 4, text: ['周一至周三只开放给有预约的旅客搭乘。'] }
              ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfkansai/zh/グループ 261.png'),
          title: ['大阪散步美食券(2张)'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [
                { cid: 1, text: ['●大阪市中央区道顿崛1丁目16 沿着河边B1', '(Dotonbori Riverside Grill Wonder)'] },
                ]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['http://gourmet-walk.com/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请至兑换所出示票券，可获得2张餐券。'] },
                { cid: 2, text: ['餐券请交由店家处理，勿自行撕下。'] },
                { cid: 3, text: ['无法任意变更散步美食家合作餐点。'] },
                { cid: 4, text: ['剩下的餐券无法再换回现金,敬请知悉见谅。'] },
                { cid: 5, text: ['兑换所的营业时间请事先至官网确认。'] }
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfkansai/298_tc.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1WA4y1Q7wt/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['298烧肉1000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '●298难波店(目前暂停营业)',
                '大阪府大阪市中央区千日前1-7-9 4F',
                '●298心斋桥店',
                '大阪府大阪市中央区心斎桥筋1-2-8',
                '●298梅田3号店',
                '大阪府大阪市北区堂山町5-8',
                '●298三宮店<span style="color:#FBB03B;">(神户)</span>',
                '神戸市中央区北长狭通1-4-2 B1',
                '●298福岛店',
                '大阪府大阪市福岛区福岛6-1-39'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['298难波店'],noColor:true },
                { cid: 2, text: ['http://www.ajibil.com/shop/298N.html'] },
                { cid: 3, text: ['298心斋桥店'],noColor:true },
                { cid: 4, text: ['https://298shinsaibashi.owst.jp/zh-cn/'] },
                { cid: 5, text: ['298梅田3号店'],noColor:true },
                { cid: 6, text: ['https://ajibiru-kashin-umeda.owst.jp/zh-cn/'] },
                { cid: 7, text: [`298三宫店 <span style="color:#FBB03B;">(神户)</span>`],noColor:true },
                { cid: 8, text: ['http://www.ajibil.com/shop/298S.html'] },
                { cid: 9, text: ['298福岛店'],noColor:true },
                { cid: 10, text: ['https://r.gnavi.co.jp/jm0fdd5d0000/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['298三宫店位于神户。'] },
                { cid: 4, text: ['1000日元优惠券可从以下5家店铺中任选一家做使用：','298难波店、298心斋桥店、298梅田3号店、298三宫店、298福岛店。'] },
                { cid: 5, text: ['有可能会因为店家客满而无法使用的状况，请见谅。 '] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hfkansai/nonotori_CH.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1fP411R7ZX/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['野乃鸟 1000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '●野乃鳥なんば堂',
                '大阪市中央区难波 3-7-19 GEMS难波8F',
                '●KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(神户)</span>',
                '神户市中央区北长狭通1-1-1 EKIZO神戸三宫',
                '●野乃鳥 梅味堂',
                '大阪市北区中崎西3-3-15 JR高架下34'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['●野乃鳥なんば堂'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/nambado/'] },
                { cid: 3, text: ['●KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(神户)</span>'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/kobe-yakitori-stand/'] },
                { cid: 5, text: ['●野乃鳥 梅味堂'],noColor:true },
                { cid: 6, text: ['https://nonotory.jp/stores/umemido/'] },
                ]
            },
            {
     
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['KOBE YAKITORI STAND 野乃鳥位于神户。'] },
                { cid: 4, text: ['1000日元优惠券可从以下3家店铺中任选一家做使用。野乃鳥なんば堂、KOBE YAKITORI STAND 野乃鳥、野乃鳥 梅味堂。'] },
                { cid: 5, text: ['入店时需额外支付小菜费用。'] },
                { cid: 6, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                { cid: 7, text: ['结帐时出示Travel Contents APP扫码就可享有10%点数回馈。 APP下载连结：','<br><a style="color: rgb(33, 157, 204);border-bottom: 1px solid;" href="https://tripellet.com/tcapp-download-b/sc">https://tripellet.com/tcapp-download-b/sc</a>'] },
                ]
            }
          ]
        },
        {
          id: 46,
          img: require('@/assets/images/hfkansai/zh_tw/heniuunou_TC.png'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['和牛烧肉Unou 1000日圆优惠券','<span>(期间限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '别馆：大阪府大阪市西成区玉出中2-15-22',
                '都岛本通店：大阪府大阪市都岛区都岛本通3-27-7',
                '福岛本店：大阪府大阪市福岛区福岛2-7-7',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['别馆：无官网'],noColor:true },
                { cid: 2, text: ['都岛本通店：'],noColor:true },
                { cid: 3, text: ['https://www.hotpepper.jp/strJ000716500/'] },
                { cid: 4, text: ['https://instagram.com/unou_gram?igshid=YzAwZjE1ZTI0Zg=='] },
                { cid: 5, text: ['福岛本店：'],noColor:true },
                { cid: 6, text: ['https://unoufukushima.owst.jp/'] },
                { cid: 7, text: ['https://www.instagram.com/unou_7319/?hl=ja'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['若有价差需现场支付差额。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                { cid: 4, text: ['一间店铺限使用一次。','例:已在别馆使用过了，但都岛本通店仍可使用。'] },
                { cid: 5, text: ['繁忙时段限时90分钟。'] },
              ]
            }
          ]
        },
        {
          id: 47,
          img: require('@/assets/images/hfkansai/zh_tw/Nanjamonja_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Nanjamonja 1000日圆优惠券 ','<span>(期间限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '大阪府大阪市中央区东心斋桥1-13-19 NT大楼2F',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://gurunavi.com/zh-hans/k342800/rst/']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['使用1000日圆优惠券享用大阪着名的御好烧。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['营业时间请事先至官网确认。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
              ]
            }
          ]
        },
        {
          id: 48,
          img: require('@/assets/images/hfkansai/zh_tw/95jiaoshi_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['大阪三线教室 1小时体验(需事先预约)','<span>(期间限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '大阪府大阪市生野区巽中4-4-5',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://500en-sanshin-school.jimdosite.com/']},
                { cid: 2, text: ['(预约网站)'],noColor:true},
                { cid: 3, text: ['https://airrsv.net/sansin/calendar']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['产品内容:1小时体验费。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
              ]
            }
          ]
        },
        {
          id: 49,
          img: require('@/assets/images/hfkansai/zh_tw/96jas_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['宗教法人 久安寺 坐禅、抄经、画佛体验 (需事先预约)','<span>(期间限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '大阪府池田市伏尾町697',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://kyuanji.jp/']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['产品内容：坐禅、抄经、画佛体验。 三者择一，包含入场费300日元。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
              ]
            }
          ]
        },
        {
          id: 50,
          img: require('@/assets/images/hfkansai/zh_tw/97rwgj_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['染屋贵久 染色体验 1000日圆优惠券(需事先预约) ','<span>(期间限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '大阪府池田市荣町6-17',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://someyakikyu.com/']},
                { cid: 2, text: ['(预约网站)'],noColor:true},
                { cid: 3, text: ['https://kikyu.urkt.in/ja/direct/offices/65/courses']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['营业时间请事先至官网确认。'] },
              ]
            }
          ]
        },
        {
          id: 51,
          img: require('@/assets/images/hfkansai/zh_tw/98bbdc_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['日本杀阵道协会 本部道场 体验1000日圆优惠券(需事先预约)','<span>(期间限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '大阪府大阪市西区北堀江1丁目1-27 (3F)',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://nihontatedokyokai.com/']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['营业时间请事先至官网确认。'] },
                { cid: 4, text: ['需提前预约。'] },
              ]
            }
          ]
        },
        {
          id: 52,
          img: require('@/assets/images/hfkansai/zh_tw/Kawaii_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Kawaii Osaka KIMONO SHOP 和服购买/和服体验 1000日圆优惠券 ','<span>(期间限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '大阪府大阪市中央区宗右卫门町1-9',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://kawaii-osaka.jp/']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。(和服购买/和服体验)'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['营业时间请事先至官网确认。'] },
                { cid: 4, text: ['可能因预约状况等因素无法提供顺畅的服务，建议透过网站进行线上预订。'] },
              ]
            }
          ]
        },
        // 
        {
          id: 17,
          img: require('@/assets/images/hfkansai/zh/SantaMaria_tc.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1BG41147hH/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['圣玛丽亚号白天游览 乘船券','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒552-0022 大阪府大阪市港区海岸通1丁目1-10'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://suijo-bus.osaka/language/santamaria/'] }
                ]
            },
            {
     
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['运行日程请在官方网站上确认。'] },
                { cid: 2, text: ['有时会因强风、降雨等气象条件而停止运行。'] },
                { cid: 3, text: ['满座的话可能会无法乘船。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 18,
          img: require('@/assets/images/hfkansai/zh/Paragliding_tc.png'),
          title: ['滑翔伞单人体验 1000日圆优惠券','<span>(2023年7月~8月无法体验)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '大阪府大阪市此花区北港绿地2-1-44(舞洲体育岛)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/paraglider/index.html'] },
                { cid: 2, text: ['预约网址(仅日文)'],noColor:true },
                { cid: 3, text: [' https://osakamachipara.com/'] },
                ]
            },
            {
     
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['全预约制营业，请事先预约。没有事先预约的话，可能会有休业的情况。请务必确认后再使用。'] },
                { cid: 2, text: ['完全预约制，于官网预约(2/20起开放)。'] },
                { cid: 3, text: ['请携带方便运动的衣服和鞋子。'] },
                { cid: 4, text: ['其馀事项请参考当天的流程表或体验报名表。'] },
                { cid: 5, text: ['体验限制(年龄:3岁以上/身高:190cm以下/体重:80kg以下)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 19,
          img: require('@/assets/images/hfkansai/zh/SUPExperience_tc.png'),
          title: ['水上散步 SUP体验 1000日圆优惠券','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒554-0042大阪府大阪市此花区北港绿地1丁目2-25'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/mishima-citysup.html'] },
                ]
            },
            {
     
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['圆形SUP/BIGSUP 中学生以下无法单独体验。'] },
                { cid: 2, text: ['自行车SUP有身高限制。'] },
                { cid: 3, text: ['饮酒者/孕妇无法体验。'] },
                { cid: 4, text: ['可能因天候等因素取消。'] },
                { cid: 5, text: ['请穿着湿了也没关係的衣服。','<br>(有被水花或落船弄湿的可能性。)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 20,
          img: require('@/assets/images/hfkansai/zh/KeihanRailwa_tc.png'),
          title: ['京阪电车 京都大阪 观光1日券 ','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [
                { cid: 1, text: ['京阪电车 (淀屋桥站)'],noColor:true },
                { cid: 2, text: ['大阪市中央区北浜３丁目1－25（指定席票务窗口）','<br>'],noColor:true },
                { cid: 3, text: ['京阪电车 (北浜站)'],noColor:true },
                { cid: 4, text: ['大阪府大阪市中央区北滨１丁目８－１６（指定席票务窗口）','<br>'],noColor:true },
                { cid: 5, text: ['京阪电车 (天满桥站)'],noColor:true },
                { cid: 6, text: ['大阪府大阪市中央区谷町１丁目１（车站票务所）','<br>'],noColor:true },
                { cid: 7, text: ['京阪电车 (京桥站)'],noColor:true },
                { cid: 8, text: ['大阪府大阪市都岛区东野田町２丁目１−３８（指定席票务窗口）','<br>'],noColor:true },
                { cid: 9, text: ['京阪电车 (三条站)'],noColor:true },
                { cid: 10, text: ['京都府京都市东山区大桥町 三条大桥东诘（指定席票务窗口）'],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.keihan.co.jp/travel/cn/trains/passes-for-visitors-to-japan/kyoto-osaka.html'] },
                ]
            },
            {
     
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['乘车前必须兑换为实体乘车券。'] },
                { cid: 2, text: ['兑换后实体票券的有效期限以票面后印刷的期限为准。'] },
                { cid: 3, text: ['本票券可乘坐「普通」「区间急行」「准急」「急行」「快速急行」「特急」「快速特急」电车。不可直接用于乘坐「premium car」(同一辆列车中的指定席车厢)。'] },
                { cid: 4, text: ['乘坐「premium car」列车需在车站另行购买指定席票券'] },
                ]
            }
          ]
        },
        // 
        {
          id: 21,
          img: require('@/assets/images/hfkansai/zh/CarThemePavilion_tc.png'),
          title: ['日本头文字D跑车主题馆代金券','(价值5500日元)','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒551-0031 大阪府大阪市大正区泉尾4丁目12-21'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://mrhiro-jdm.com/'] },
                { cid: 2, text: ['官网预约'],noColor:true },
                { cid: 3, text: ['https://mrhiro-jdm.com/rental'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['內容包含: ','<br>①日本头文字D跑车租赁优惠2000日元','<br>②专业赛车模拟器30分以上体验优惠2000日元','<br>③电动漂移车体验20分以上体验优惠1500日元'] },
                { cid: 2, text: ['一张票，多人分别体验也可以，','<br>例如: 大人玩赛车模拟器，小孩可以玩漂移车。'] },
                { cid: 3, text: ['仅限当天体验。'] },
                { cid: 4, text: ['头文字D跑车租赁建议提前在官网预约。'] },
                { cid: 5, text: ['有可能会因为客满而无法使用的状况，请见谅。'] },
                { cid: 6, text: ['跑车租赁体验需提供驾照，请提前在官网确认相关讯息。'] },
                ]
            }
          ]
        },
        {
          id: 22,
          img: require('@/assets/images/hfkansai/zh/terminal_tc.png'),
          title: ['大阪府「16美食街」 1300日元分餐券','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒530-0001 大阪市北区梅田3-1-3 JR大阪站5楼 时空广场 「BAR del SOLE」','<span style="color:red;">※「BAR del SOLE」7/18（二）～21（五）暂停营业，期间无法兑换餐券</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://osakastationcity.com/16gourmet/'] },
                { cid: 2, text: ['https://osakastationcity.com/pdf/ichiroku_inbound.pdf'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['换取可在对象店铺使用之餐券1000日元分x1、300日元分x1，一共2张。'] },
                { cid: 2, text: ['此餐券可在下列店铺中当作现金券使用。',
                '<br>・大阪车站南门大楼16楼「16美食街」 「宫崎SHABUSHABU雾峰」「A・ran」「大阪竹叶亭」「uwo佐」「银座秃头天」「洋面屋五右卫门」「沙龙卵与我」「ST-MARC CAFE」「cafe 英国屋」「肉之匠 永田屋」「弦」「Hakkakuan」「PIER 30 GRILL」「中国料理 罗德斯」',
                '<br>・大阪车站南门大楼1楼 「Cafe de Clever」',
                '<br>・大阪车站南门大楼地下1楼 「San Marco」',
                '<br>・时空广场（大阪站5楼）「BAR del SOLE」'] },
                { cid: 3, text: ['此餐券一次结账可使用多张。'] },
                { cid: 4, text: ['此餐券不可换成现金，也不可找零。'] },
                { cid: 5, text: ['此餐券由所使用之店铺收回。'] },
                { cid: 6, text: ['此餐券在大丸梅田店、大阪格兰比亚酒店、LUCUA 大阪、Eki Marché Osaka、大阪车站城电影院等场所中无法使用。'] },
                { cid: 7, text: ['可使用之店铺有可能变更。'] },
                { cid: 8, text: ['营业时间有可能无预告变更。另外，若是因为临时停业、客满等情况无法使用餐券也无法退款。'] },
                { cid: 9, text: ['一旦兑换餐券后，无法取消、退款。'] },
                { cid: 10, text: ['不能直接出示二维码使用。请务必先兑换成餐券后至各店铺使用。'] },
                { cid: 11, text: ['无法使用于对象店铺之外的地方。'] },
                ]
            }
          ]
        },
        {
          id: 23,
          img: require('@/assets/images/hfkansai/zh/meitian_tc.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1NT411w7ju/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['大阪梅田空中庭园展望台入场卷','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒531-6039 大坂市北区大淀中1-1-88 梅田蓝天大厦 (39楼售票柜台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.skybldg.co.jp/cn/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['大阪淀川烟花大会(2023/8/5)、跨年等特别营业日不可使用。'] },
                { cid: 2, text: ['使用轮椅的游客请在东塔1楼乘坐高楼层专用电梯。'] },
                { cid: 3, text: ['暴风雨等天气时，有可能无法进入楼顶，敬请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 24,
          img: require('@/assets/images/hfkansai/zh/CHOICE_tc.png'),
          title: ['BAR CHOICE　1000日元优惠券','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒542-0083  中央区东心斎桥1-8-24　米山大楼１F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://choice-cola.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅'] },
                ]
            }
          ]
        },
        {
          id: 25,
          img: require('@/assets/images/hfkansai/zh/Wonder_tc.png'),
          title: ['Dotonbori Grill & Bar Wonder 1000円优惠券','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒542-0071 大阪市中央区道顿堀1-1-6 川沿B1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://wonderpub.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅'] },
                { cid: 5, text: ['内有贩售含酒精饮料，须年满20岁才可以购买'] },
                ]
            }
          ]
        },
        {
          id: 26,
          img: require('@/assets/images/hfkansai/zh/chisozanmai_tc.png'),
          title: ['大丸松坂屋百货店 驰走三昧 大丸梅田店 1000円优惠券','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒530-8202 大阪市北區梅田3-1-1 大丸 大阪・梅田店14F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.daimaru.co.jp.c.md.hpcn.transer-cn.com/umedamise/restaurant/chisozanmai.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。(仅适用于每人消费5000日元以上）'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 28,
          img: require('@/assets/images/hfkansai/zh/LUCUAosaka_tc.jpg'),
          title: ['大阪站直通的购物中心「LUCUA osaka」 1000日元分馆内共通商品券','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                'LUCUA 1100 2楼 服务中心','<span style="color:red;">※受理至2023年12月31日为止</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.lucua.jp/zh/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['內容包含: 1000日元份优惠券1张'] },
                { cid: 2, text: ['有效期限：','在2023年8月31日前兑换的商品券，有限期限到2023年8月31日。','在2023年9月1日后兑换的商品券，有限期限到2024年1月31日。'] },
                { cid: 3, text: ['此商品券不可换成现金，也不可购买其他商品券、邮票、明信片等商品。'] },
                { cid: 4, text: ['此商品券不可找零。'] },
                { cid: 5, text: ['此商品券在LUCUA内的一部分店铺中无法使用．（不可使用之店铺：LUCUA 5F「C-pla」、LUCUA 9F「FUJIMOTO眼科」、LUCUA 1100（Ihre） B1F「麦当劳」）另外，目前展示的店铺图为2023年3月的情况，有可能变更店铺。'] },
                { cid: 6, text: ['营业时间有可能无预告变更。另外，若是因为临时停业、客满等情况无法使用商品券也无法退款。'] },
                ]
            }
          ]
        },
        {
          id: 29,
          img: require('@/assets/images/hfkansai/zh/EKI_MARCHE_tc.jpg'),
          title: ['大阪站内购物中心「EKI MARCHÉ大阪」馆内共通券 1000日元分','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '「EKI MARCHÉ大阪」中的服务中心','<span style="color:red;">※受理至2023年12月31日为止</span>','<span style="color:red;">※预订日起90天内，电子票可兑换优惠券。</span>','<span style="color:red;">※休馆日5/9（二）、9/5（二）无法兑换、使用优惠券。</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://osaka.ekimaru.com/sc'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['內容包含: 500日元份優惠券2張'] },
                { cid: 2, text: ['实体优惠券的有效期是电子凭证兑换的下个月末截止。（例：若于3/15日兑换成实体券，实体券有效期限为4/30）'] },
                { cid: 3, text: ['此优惠券一次结账可使用多张。'] },
                { cid: 4, text: ['此优惠券不可换成现金，也不可找零。'] },
                { cid: 5, text: ['可使用之店铺有可能变更。营业时间有可能无预告变更。另外，若是因为临时停业、客满等情况无法使用优惠券也无法退款。'] },
                { cid: 6, text: ['一旦兑换优惠券后，无法取消、退款。'] },
                { cid: 7, text: ['不能直接出示二维码使用。请务必先兑换成优惠券后至各店铺使用。'] },
                { cid: 8, text: ['无法使用于对象店铺之外的地方。（EKI MARCHÉ内无法使用的店铺：JTB、银行ATM、DreamShop）'] },
                { cid: 9, text: ['总共500人份，兑换完为止。'] },
                ]
            }
          ]
        },
        {
          id: 31,
          img: require('@/assets/images/hfkansai/zh/Yamachan_tc.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1FV4y1z7k3/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['大阪梅田章鱼烧市场 Yamachan HEP FIVE店 1000日元优惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北区角田町５−１５ HEP Five １楼'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 32,
          img: require('@/assets/images/hfkansai/zh/HEP_NAVIO_tc.jpg'),
          title: ['大阪梅田章鱼烧市场 章鱼烧十八番 HEP NAVIO店 1000日元优惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北区角田町７－１０ HEP Five １楼'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 33,
          img: require('@/assets/images/hfkansai/zh/Yashiki_tc.jpg'),
          title: ['大阪梅田章鱼烧市场 甲贺流 HEP NAVIO店 1000日元优惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北区角田町７－１０ HEP Five １楼'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 34,
          img: require('@/assets/images/hfkansai/zh/kukuru_HEP_FIVE_tc.jpg'),
          title: ['大阪梅田章鱼烧市场 kukuru HEP FIVE店 1000日元优惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北区角田町５−１５ HEP Five １楼'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 35,
          img: require('@/assets/images/hfkansai/zh/HEP_NAVIO_Umeda_tc.jpg'),
          title: ['大阪梅田章鱼烧市场 会津屋 梅田HEP NAVIO店 1000日元优惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北区角田町７－１０ HEP Five １楼'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 36,
          img: require('@/assets/images/hfkansai/zh/bistecca_tc.png'),
          title: ['bistecca IL FORNO 1000日元优惠券','<span>(2023/8/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒550-0015大阪府大坂市西区南堀江1-5-17 Canal terrace堀江 西栋1F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://bistecca-ilforno.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 37,
          img: require('@/assets/images/hfkansai/zh/MIO_TC.jpg'),
          title: ['天王寺MIO 馆内共通劵1500元优惠券','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒543-0055　大坂市天王寺区悲田院町10-39 "天王寺MIO 本馆" 2F信息柜台','※受理至2023年12月30日为止'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.tennoji-mio.co.jp/lang/cn/access/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['仅在天王寺 MIO（主楼和广场楼）内的店铺有效。'] },
                { cid: 2, text: ['部分商店不适用。使用此优惠券时，请询问各商店的工作人员。 '] },
                { cid: 3, text: ['请用现金支付优惠券的差额。不找零。'] },
                { cid: 4, text: ['优惠券不可退款，也不可兑换现金。'] },
                { cid: 5, text: ['我们对优惠券的火灾、失窃、丢失或损毁概不负责。'] },
                { cid: 6, text: ['部分商店的营业时间与此不同。营业时间如有变更，恕不另行通知。'] },
                { cid: 7, text: ['内容包含: 500日元份优惠券3张'] },
                ]
            }
          ]
        },
        {
          id: 43,
          img: require('@/assets/images/hfkansai/zh_tw/EDION_TC.jpg'),
          title: ['EDION难波観光组合套餐 1000元优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '大阪市中央区难波3丁目2番18号 EDION难波本店8F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://namba.edion.com/en/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['套餐包含:','<br>・忍者逃脱游戏体验(价值1000日币)<br>・免费行李寄放1日(价值800日币)<br>・化妆室 1小时使用 (价值300日币) ※可以使用最新的家电和化妆品<br>・充电站 30分钟充电 (价值200日圆) ※iPhone和Android皆可利用'] },
                { cid: 3, text: ['营业时间请事先至官网确认。'] },
                { cid: 4, text: ['若有价差需现场支付差额。'] },
                { cid: 5, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
              ]
            }
          ]
        },
        // {
        //   id: 44,
        //   img: require('@/assets/images/hfkansai/zh_tw/rentcar_tc.png'),
        //   title: ['车站租车2000日元的优惠券'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '兑换地点',
        //       content: [{ cid: 1, text: [
        //         '（1）车站租车　新大阪营业所',
        //         '大阪市淀川区西中岛5-16-1 JR新大阪站（南出口1楼）',
        //         '（2）车站租车　京都营业所',
        //         '京都市下京区东盐小路町940JR京都站（车站大楼停车场1楼）',
        //         '（3）车站租车　新神户营业所',
        //         '神户市中央区加纳町1-3-1 JR新神户站（1楼中央大厅）',
        //         '（4）车站租车　姬路营业所',
        //         '兵库县姫路市南站前町125JR姫路站（中央检票口向左，南出口向左，车站前停车场内）',
        //         ],noColor:true }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '官方网站',
        //       content: [
        //         { cid: 1, text: ['（1）车站租车　新大阪营业所'],noColor:true },
        //         { cid: 2, text: ['https://www.ekiren.com/eigyosyo?eigyou=270200'] },
        //         { cid: 3, text: ['（2）车站租车　京都营业所'],noColor:true },
        //         { cid: 4, text: ['https://www.ekiren.com/eigyosyo?eigyou=260400'] },
        //         { cid: 5, text: ['（3）车站租车　新神户营业所'],noColor:true },
        //         { cid: 6, text: ['https://www.ekiren.com/eigyosyo?eigyou=290200'] },
        //         { cid: 7, text: ['（4）车站租车　姬路营业所'],noColor:true },
        //         { cid: 8, text: ['https://www.ekiren.com/eigyosyo?eigyou=290800'] },
        //         ]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事项',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['可以享受2000日元的优惠券。'] },
        //         { cid: 2, text: ['只限当日使用・不能预约。'] },
        //         { cid: 3, text: ['使用方法：在对象店铺申请租车时，请出示关西乐享周游券二维码、驾照、护照（日本国籍以外的人）。'] },
        //         { cid: 4, text: ['营业时间请事先确认官方站点。'] },
        //         { cid: 5, text: ['费用差额部分需要现场支付。费用差额部分需要现场支付。'] },
        //         { cid: 6, text: ['不能指定车型。'] },
        //         { cid: 7, text: ['若预约客满可能无法使用。请见谅。'] },
        //       ]
        //     }
        //   ]
        // },
        {
          id: 45,
          img: require('@/assets/images/hfkansai/zh/mingShi_sc.png'),
          title: ['明石寿司优惠券「技艺珍品」，2,000日元优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '兵库县石市大明石町1-1-23　（明石问讯处）',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.yokoso-akashi.jp/coupon.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含内容：优惠券面值2000日元，可用于享用明石寿司选单（1人份）。'] },
                { cid: 2, text: ['请事先查看官方网站以确认营业时间。'] },
                { cid: 3, text: ['因食材进货状况与时令季节，刊载餐奌的部分内容可能会有所更动，恕不另行通知，敬请见谅。'] },
              ]
            }
          ]
        },
        {
          id: 38,
          img: require('@/assets/images/hfkansai/zh/ADVENTURE_WORLD_TC.jpg'),
          title: ['动物游乐园 冒险大世界 1日入场劵1000日元优惠券','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒649-2201 和歌山県西牟娄郡白滨町坚田2399番地 (售票窗口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.aws-s.com/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。(只限入场劵) '] },
                { cid: 2, text: ['营业时间请事先至官网确认。 '] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfkansai/zh/グループ 255.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1gB4y157vP/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['京都铁道博物馆','<span>(2023/8/18~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['京都府京都市下京区观喜寺町 (博物馆入口)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.kyotorailwaymuseum.jp/sc/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['限当日能多次入馆，再入馆时请出示兑换后的纪念入馆券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] }
              ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfkansai/zh/グループ 256.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1ht4y1n7J4/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['京都岚山美食优惠劵+岚电1日通票'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['京都府京都市右京区嵯峨天龙寺造路町20-2', '(岚山站咨询处)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.keifuku.co.jp/cms/wp-content/uploads/cc0d12d1bb04c0b9865d0595a5d770a9-768x975.jpg'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['电车时刻表请在官方网站上确认。'] },
                { cid: 2, text: ['美食优惠券对象店铺请在官方网站上确认。'] },
                { cid: 3, text: ['剩下的美食优惠券不能兑现。'] },
              ]
            }
          ]
        },
        // 
        {
          id: 10,
          img: require('@/assets/images/hfkansai/sake_CH.jpg'),
          title: ['Japanese SAKE Festival in KYOTO ','「Sake Spring 」2023夏日祭典小酌入场券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒606-8343 京都市左京区岡崎成胜寺町9番地の1','(京都市劝业馆MIYAKO MESSE 3Ｆ 第3展示场)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.sakesp.com/2023summer'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['活动期间：2023/7/8~2023/7/9'] },
                { cid: 2, text: ['此为品鉴活动。年龄未满20岁的人不得参加。'] },
                { cid: 3, text: ['一旦离场，将无法再次入场。'] },
                { cid: 4, text: ['请提前查看官方网站以确认营业时间。'] },
              ]
            }
          ]
        },
        {
          id: 39,
          img: require('@/assets/images/hfkansai/zh_tw/TOEI_TC.jpg'),
          title: ['京都东映太秦映画村入场券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '京都府京都市右京区太秦东蜂冈町10'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://global.toei-eigamura.com/zh_cn/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请提前查看官方网站以确认营业时间。'] },
                { cid: 2, text: ['各游乐设施有使用限制，请事先至官网确认。'] },
              ]
            }
          ]
        },
        {
          id: 40,
          img: require('@/assets/images/hfkansai/zh/pph_SC.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1GB4y15724/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['琵琶湖大津王子饭店馆内共通券 1,500日圆'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '滋贺县大津市Niono滨4-7-7'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.princehotels.cn/otsu/zh-hans/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['馆内共通券的期限为发行后一周。过期无效。'] },
                { cid: 2, text: ['本券不能用于住宿费。'] },
                { cid: 3, text: ['差额请以现金支付。恕不找零。'] },
                { cid: 4, text: ['营业时间有可能无预告变更。另外，若是因为临时停业、客满等原因不能使用的情况也无法退款。'] },
              ]
            }
          ]
        },
        {
          id: 41,
          img: require('@/assets/images/hfkansai/zh/PASSPORT_SC.jpg'),
          title: ['长滨散步PASSPORT (2日)'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '长浜市北船町1-5（JR长浜站内）'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://kitabiwako.jp/zh/travel_guide'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['PASSPORT的使用开始日(含)起2天有效。　※并非48小时'] },
                { cid: 2, text: ['一个设施只能入场一次，不可以重複使用。'] },
                { cid: 3, text: ['请事先确认各店铺、设施的营业时间和休息日。'] },
                { cid: 4, text: ['各设施的特别展会有需要另外追加费用。'] },
              ]
            }
          ]
        },
        {
          id: 42,
          img: require('@/assets/images/hfkansai/zh_tw/yxt_TC.jpg'),
          title: ['一心堂 1000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '奈良县奈良市上三条町3番地之9'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://issindo-nara.jp/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
              ]
            }
          ]
        },
        {
          id: 30,
          img: require('@/assets/images/hfkansai/zh/Yoshitaya_tc.jpg'),
          title: ['吉高屋 龟印美肌香皂 兑换券','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒651-1401　兵库县神户市北区有马町259'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.yoshitakaya.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['图片仅供参考，实品请以实物为主。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                ]
            }
          ]
        },
        {
          id: 27,
          img: require('@/assets/images/hfkansai/zh/carbonated_tc.jpg'),
          title: ['元祖三津森本舖 炭酸煎饼罐装30片入','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒651-1401　兵库县神户市北区有马町290-1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['http://tansan.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['店舗有人群拥挤的可能性。届时，需要与一般顾客一同等待。此票券并无优先权。'] },
                { cid: 2, text: ['图片仅供参考，实品请以实物为主。'] },
                { cid: 3, text: ['营业时间请事先至官网确认。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 11,
          img: require('@/assets/images/hfkansai/zh/グループ 257.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://www.bilibili.com/video/BV1aT411w7KC/?share_source=copy_web&vd_source=83a4bbfd76817d0a3d3a912e25e413bd',
          title: ['神户布引香草园入场劵及缆车乘車劵'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['兵库县神户市中央区北野町1-4-3（山麓站4楼咨询台）'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.kobeherb.com/cn/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['晚间开放期间(17:00开始)，仅限眺望区允许进入。'] },
                { cid: 2, text: ['全年无休(冬季因年度检修将停运/闭园约1个月)'] },
                { cid: 3, text: ['如遇大风、雷电，有可能停运或暂停开放。'] },
              ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfkansai/zh/グループ 258.png'),
          title: ['神户浪漫协奏曲号1000日元优惠劵'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['神户市中央区东川崎町1-6-1 神户Harborland umie 1F', '(Concerto乘船处)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://thekobecruise.com/concerto/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['运行日程请在官方网站上确认。'] },
                { cid: 2, text: ['满座的话可能会无法乘船。'] },
                { cid: 3, text: ['费用差额需要在现场支付。'] },
              ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfkansai/zh/グループ 259.png'),
          title: ['MOSAIC马赛克摩天轮'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['神户市中央区东川崎町1-6-1 神户Harborland umie (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://umie.jp/features/mosaickanransya'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['因天气不佳或设施维护会临时停业。'] },
              ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfkansai/zh/グループ 260.png'),
          title: ['观音屋 1000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['神户市中央区东川崎町1-6-1 神户Harborland umie 1F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/76'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可使用1000日圆优惠券。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
              ]
            }
          ]
        },
        {
          id: 15,
          img: require('@/assets/images/hfkansai/zh/グループ 248.png'),
          title: ['Tonton Tonkatsu 1000日圆', '优惠券 / 指定套餐'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['神户市中央区东川崎町1-6-1 神户Harborland umie 2F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/93'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券/套餐。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] }
              ]
            }
          ]
        },
        {
          id: 16,
          img: require('@/assets/images/hfkansai/zh/グループ 247.png'),
          title: ['Frantz 甜点优惠劵'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['神戶市中央區東川崎町1-6-1 神戶Harborland umie 2F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/98'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: false, //是否启用ul的数字前缀 //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['3种甜点中任选1种。'] },
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFKansai&l=sc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-kansai-1-week-free-pass/sc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.xiao_btn_img{
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 16px;
  a{
    height: 32px;
    // flex: 1;
    width: 33%;
    // text-align: center;
  }
  img{
    // flex: 1;
    width: 90%;
    max-width: 144px;
    height: auto;
    // height: 100%;
  }
}
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
.dltAbq:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>